import { QueryClient, useQuery } from '@tanstack/react-query'
import { useId } from 'react'
import {
  ActionFunction,
  LoaderFunction,
  Navigate,
  Route,
  RouteObject,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  useRouteError,
} from 'react-router'
import { RootRouteErrorBoundary } from '~/components/error-boundary/root-error'
import { Container } from '~/components/ui/container'
import { vars } from '~/lib/env'
import {
  type QueryLoaderFunction,
  type QueryActionFunction,
  isHttpError,
  unpackHttpError,
} from '~/lib/query'
import { loader as organizationLoader } from '~/routes/(auth)/(org)/loader'
import { loader as projectStatusLoader } from '~/routes/(auth)/[project]/loader'
import { loader as authLoader } from '~/routes/(auth)/loader'
import { getSystemRoutes } from '~/routes/(auth)/system/routes'
import { loader as loginLoader } from '~/routes/(login)/loader'
import { RootLayout } from '~/routes/layout'

export function createRouter(queryClient: QueryClient) {
  const routes = getRoutes(queryClient)
  return createBrowserRouter(routes, {
    future: {
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_relativeSplatPath: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  })
}

export type RouterType = ReturnType<typeof createRouter>

function DO_NOT_SHIP_TO_PRODUCTION_ErrorBoudary() {
  const id = useId()
  const error = useRouteError()

  console.warn('ROOT_ERROR_BOUNDARY_ERROR', error)

  const { data } = useQuery({
    queryKey: ['root-error-boundary', id],
    queryFn: () => {
      return isHttpError(error) ? unpackHttpError(error) : error
    },
  })

  return (
    <div>
      <h1>Error 💀</h1>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  )
}

type LazyRoute = {
  action?: QueryActionFunction
  Component?: React.ComponentType
  ErrorBoundary?: React.ComponentType
  Layout?: React.ComponentType
  loader?: QueryLoaderFunction
}

type LazyRouteReturn = {
  action?: ActionFunction
  Component?: React.ComponentType
  ErrorBoundary?: React.ComponentType
  loader?: LoaderFunction
}

const HydrationFallback = () => (
  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 animate-pulse">
    Loading...
  </div>
)

export function createLazy(queryClient: QueryClient) {
  return <T extends LazyRoute>(mod: T) => {
    const finalRoute: LazyRouteReturn = {}

    if (mod.Layout) finalRoute.Component = mod.Layout
    if (mod.Component) finalRoute.Component = mod.Component

    if (mod.ErrorBoundary) finalRoute.ErrorBoundary = mod.ErrorBoundary

    if (mod.action) finalRoute.action = mod.action(queryClient)
    if (mod.loader) finalRoute.loader = mod.loader(queryClient)

    return finalRoute as any
  }
}

function getRoutes(queryClient: QueryClient): RouteObject[] {
  const lazy = createLazy(queryClient)
  return createRoutesFromElements(
    <Route
      element={<RootLayout />}
      errorElement={<RootRouteErrorBoundary />}
      HydrateFallback={HydrationFallback}
    >
      <Route path="/" loader={() => redirect('/login')} />
      <Route loader={loginLoader(queryClient)}>
        <Route
          path="login"
          errorElement={<DO_NOT_SHIP_TO_PRODUCTION_ErrorBoudary />}
          lazy={() => import('~/routes/(login)/login.page').then(lazy)}
        />
        <Route
          path="sso"
          element={<pre>"/login/sso" is not implemented</pre>}
        />
        <Route
          path="sign-up"
          lazy={() => import('~/routes/(login)/sign-up.page').then(lazy)}
        />
        <Route
          path="forgot-password"
          lazy={() =>
            import('~/routes/(login)/forgot-password.page').then(lazy)
          }
        />
        <Route
          path="reset-password/:secret"
          lazy={() => import('~/routes/(login)/reset-password.page').then(lazy)}
        />
      </Route>

      <Route
        path="/:organizationSlug/login"
        errorElement={<DO_NOT_SHIP_TO_PRODUCTION_ErrorBoudary />}
        loader={loginLoader(queryClient)}
        lazy={() => import('~/routes/(login)/login.page').then(lazy)}
      />
      <Route
        path="/exchange"
        lazy={() => import('~/routes/exchange.page').then(lazy)}
      />
      <Route
        path="/social-login-fail"
        lazy={() => import('~/routes/social-login-fail.page').then(lazy)}
      />
      <Route
        loader={authLoader(queryClient)}
        lazy={() => import('~/routes/(auth)/layout').then(lazy)}
      >
        <Route
          path="/import/callback/GITHUB"
          lazy={() =>
            import('~/routes/(auth)/import/callback/github.page').then(lazy)
          }
        />
        <Route
          path="/import/callback/BITBUCKET"
          lazy={() =>
            import('~/routes/(auth)/import/callback/bitbucket.page').then(lazy)
          }
        />
        <Route
          path="/import/callback/GITLAB"
          lazy={() =>
            import('~/routes/(auth)/import/callback/gitlab.page').then(lazy)
          }
        />
        <Route path="/:organizationSlug/new/bitbucket" />
        <Route
          path="/logout"
          lazy={() => import('~/routes/(auth)/logout.page')}
        />
        <Route lazy={() => import('~/routes/(auth)/(user)/layout').then(lazy)}>
          <Route
            path="/profile"
            lazy={() =>
              import(
                '~/routes/(auth)/(user)/profile-settings/profile-settings.page'
              ).then(lazy)
            }
          />
          <Route
            path="/appearance"
            lazy={() =>
              import('~/routes/(auth)/(user)/appearance.page').then(lazy)
            }
          />
          <Route
            path="/organizations"
            lazy={() =>
              import('~/routes/(auth)/(user)/organizations/page').then(lazy)
            }
          />
          <Route
            path="/accept-invitation"
            lazy={() =>
              import('~/routes/(auth)/accept-invitation/page').then(lazy)
            }
          />
          <Route
            path="/connections"
            lazy={() =>
              import(`~/routes/(auth)/(user)/connections/page`).then(lazy)
            }
          />
        </Route>
        <Route loader={organizationLoader(queryClient)}>
          <Route lazy={() => import('~/routes/(auth)/(org)/error').then(lazy)}>
            <Route
              path="/:organizationSlug"
              lazy={() => import('~/routes/(auth)/(org)/layout').then(lazy)}
            >
              <Route index element={<Navigate to="projects" replace />} />
              <Route
                path="projects"
                lazy={() =>
                  import('~/routes/(auth)/(org)/projects/page').then(lazy)
                }
              />
              <Route
                path="quality-gates"
                lazy={() =>
                  import('~/routes/(auth)/(org)/quality-gates/layout').then(
                    lazy,
                  )
                }
              >
                <Route
                  path="/:organizationSlug/quality-gates/:qualityGateId"
                  lazy={() =>
                    import(
                      '~/routes/(auth)/(org)/quality-gates/[id].page'
                    ).then(lazy)
                  }
                />
              </Route>
              {/* CA-4531 Hide Quality Profiles tab */}
              {/* <Route
                path="quality-profiles"
                lazy={() =>
                  import('~/routes/(auth)/quality-profiles/page').then(lazy)
                }
              /> */}
              <Route
                path="settings"
                lazy={() =>
                  import('~/routes/(auth)/(org)/settings/page').then(lazy)
                }
              />
              {/* CA-2922 Hide Billing tab */}
              {/* <Route
              path="billing"
              loader={billingLoader(queryClient)}
              lazy={() =>
                import('~/routes/(auth)/(org)/billing/page').then(lazy)
              }
            /> */}
              <Route
                path="team"
                lazy={() => import(`~/routes/(auth)/team/page`).then(lazy)}
              />

              <Route path="*" element={<div>No such page =(</div>} />
            </Route>
          </Route>
          <Route
            lazy={() => import('~/routes/(auth)/[project]/error').then(lazy)}
          >
            <Route
              path="/:organizationSlug/projects/:projectSlug"
              lazy={() => import('~/routes/(auth)/[project]/layout').then(lazy)}
            >
              <Route index element={<Navigate to="branches" replace />} />
              <Route
                path="branches"
                lazy={() =>
                  import('~/routes/(auth)/[project]/branches/page').then(lazy)
                }
              />
              <Route
                path="settings"
                lazy={() =>
                  import('~/routes/(auth)/[project]/settings/page').then(lazy)
                }
              />
              <Route
                path="setup"
                lazy={() =>
                  import(
                    '~/routes/(auth)/[project]/setup-instructions/page'
                  ).then(lazy)
                }
              />
              <Route
                path="summary"
                lazy={() =>
                  import('~/routes/(auth)/[project]/summary/page').then(lazy)
                }
              />
              <Route path="*" element={<div>No such page</div>} />
            </Route>
          </Route>
          <Route
            lazy={() => import('~/routes/(auth)/(org)/new/layout').then(lazy)}
          >
            <Route
              path="/:organizationSlug/new/project"
              lazy={() =>
                import('~/routes/(auth)/(org)/new/project/page').then(lazy)
              }
            />
            <Route
              path="/:organizationSlug/new/project/manual"
              lazy={() =>
                import('~/routes/(auth)/(org)/new/project/manual.page').then(
                  lazy,
                )
              }
            />
            <Route
              path="/:organizationSlug/new/project/github"
              lazy={() =>
                import('~/routes/(auth)/(org)/new/project/github.page').then(
                  lazy,
                )
              }
            />
            <Route
              path="/:organizationSlug/new/project/bitbucket"
              lazy={() =>
                import('~/routes/(auth)/(org)/new/project/bitbucket.page').then(
                  lazy,
                )
              }
            />
            <Route
              path="/:organizationSlug/new/project/gitlab"
              lazy={() =>
                import('~/routes/(auth)/(org)/new/project/gitlab.page').then(
                  lazy,
                )
              }
            />
            {/* <Route
              path="/:organizationSlug/new/project/azure"
              lazy={() =>
                import('~/routes/(auth)/(org)/new/project/azure.page').then(
                  lazy,
                )
              }
            /> */}
          </Route>
          <Route
            lazy={() => import('~/routes/(auth)/(branch)/error').then(lazy)}
          >
            <Route
              path="/:organizationSlug/projects/:projectSlug/branches/:branchId"
              loader={projectStatusLoader(queryClient)}
              lazy={() => import('~/routes/(auth)/(branch)/layout').then(lazy)}
            >
              <Route index element={<Navigate to="summary" replace />} />
              <Route
                path="summary"
                lazy={() =>
                  import('~/routes/(auth)/(branch)/summary/page').then(lazy)
                }
              />
              <Route
                path="issues"
                lazy={() =>
                  import('~/routes/(auth)/(branch)/issues/page').then(lazy)
                }
              />
            </Route>
          </Route>
        </Route>
        {(process.env.NODE_ENV === 'development' ||
          vars.VITE_DEV_MODE === 'on') &&
          getSystemRoutes(lazy)}
        <Route
          path="/:organizationSlug/switch"
          lazy={() => import('~/routes/(auth)/(org)/switch/page').then(lazy)}
        />
      </Route>
    </Route>,
  )
}

function FakeRoute(props: { children: React.ReactNode }) {
  return (
    <Container.Root>
      <Container.Content>
        <h1 className="text-heading-2">{props.children}</h1>
        <p className="text-app-text-lo">😮 To be implemented...</p>
      </Container.Content>
    </Container.Root>
  )
}
